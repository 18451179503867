/* --- FONTS --- */

@import url("https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap");
/* font-family: "Cinzel Decorative", cursive; */
@import url("https://fonts.googleapis.com/css2?family=Yellowtail&display=swap");
/* font-family: 'Yellowtail', cursive; */
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap');
/* font-family: 'Cinzel', serif; */

h1, .h1 {
    font-family: 'Cinzel', serif;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0 !important;
    line-height: 1.25;
    font-size: 2.2rem;
}

h2, .h2 {
    font-family: 'Cinzel', serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.85rem;
}

h3, .h3 {
    font-size: 1.6rem;
}

h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
    font-family: 'Cinzel', serif;
    font-weight: 700;
    text-transform: uppercase;
}

h5, .h5 {
    font-size: 1.2rem;
}

p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.07rem;
}

.custom-font {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 1.11rem;
    line-height: 1.2;
}


/* --- GENERAL LAYOUT OF ALL PAGES --- */

body {
    font-family: Arial, Helvetica, sans-serif;
    background-color: #111;
    /* Photo by Markus Spiske on Unsplash, https://unsplash.com/photos/cvBBO4PzWPg, markus-spiske-cvBBO4PzWPg-unsplash.jpg, modified */
    /* background-image: url("img/coding-bg.jpg");
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: repeat;
    padding-top: 2px; */
}

.jumbotron {
    /* Photo by Brandi Redd on Unsplash, https://unsplash.com/photos/aJTiW00qqtI, modified */
    /* background-image: url("img/paper.jpg");
    height: 100%;
    background-size: cover;
    background-position: center; */
    background-color: #fff;
}

.home,
.resume {
    background-color: #fff;
    color: #000;
}

.portfolio {
    color: #fff;
}

.name {
    display: inline-block;
    vertical-align: middle;
    font-family: "Yellowtail", cursive;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 1px;
    display: inline-block;
    padding-top: 3px;
    padding-bottom: 3px;
}

.logo {
    margin-right: 0.8rem !important;
}

.user {
    font-size: 1.5rem;
    font-family: "Times New Roman", Times, serif;
}

.navbar-brand {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

header {
    margin-bottom: 1rem;
}

.clock {
    font-size: 1.1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.hr-special > *:not(:last-child) {
    visibility: hidden !important;
}

.border-quote-left {
    border-left: 0.2rem solid #fec60c !important; /* #b20104 */
}

.quote-2 {
    visibility: hidden;
}

.quote-2-parent {
    /* Image by Ali Uyar from Pixabay https://pixabay.com/illustrations/eat-sleep-code-repeat-2661935/, modified */
    background-image: url("img/code-full.jpg") !important;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

/* --- NAVIGATION LINKS --- */

.normal {
    color: #7d7d7d !important;
    font-weight: 500 !important;
    font-size: 1.07rem;
}
.active-link {
    color: #fff !important;
    font-weight: 500 !important;
    font-size: 1.07rem;
}

/* --- REGULAR LINKS --- */

.quote a:link,
.quote a:visited,
.quote a:focus,
.quote a:active {
    text-decoration: none;
    color: #5d85f4; /*#8eabfb */
}

.quote a:hover {
    text-decoration: underline;
}

.summary a:link,
.summary a:visited,
.summary a:focus,
.summary a:active, 
.education a:link,
.education a:visited,
.education a:focus,
.education a:active {
    /* text-decoration: none; */
    color: #0000ee;
}

.summary a:hover {
    text-decoration: underline;
}

.submitted a:link,
.submitted a:visited,
.submitted a:focus,
.submitted a:hover,
.submitted a:active {
    text-decoration: none;
}

/* --- GOOGLE MAP CONTACT PAGE --- */

.contact-map {
    /* Photo by Mike Swigunski on Unsplash, https://unsplash.com/photos/pFR9lF9LuN8, modified */
    background-image: url("img/cn.jpg");
    background-size: cover;
    background-position: center;
    color: #fff;
    background-color: #000;
}

.iframe-container {
    position: relative;
    width: 100%;
    height: 80%;
    /*  padding-bottom: 56.25%; */ /* Ratio 16:9 ( 100%/16*9 = 56.25% ) */
    padding-bottom: 42%; /* Ratio 16:9 ( 100%/16*9 = 56.25% ) */
} 

.iframe-container > * {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    border-radius: 0.3rem;
}

/* --- HEADER LINE DIVIDER --- */

.header-line {
    border-radius: 20px;
}

/* --- GALLERY TABS --- */

.gallery a.nav-link {
    color: black;
}

.seneca.gallery .nav-link {
    padding-left: 0.35rem;
    padding-right: 0.35rem;
    font-size: .93rem;
    font-weight: 700;
}

.york.gallery .nav-link {
    padding-left: 0.35rem;
    padding-right: 0.35rem;
    font-size: .93rem;
    font-weight: 700;
}

.gallery .nav-tabs.active {
    background-color: #1e1e30 !important;
    color: #fff !important;
    font-weight: 500;
}

.gallery .nav-tabs {
    background-color: #1e1e30 !important;
    color: #fff !important;
    font-weight: 500;
}

.content.tab-content {
    background-color: #ececf5;
    color: black;
    border: 1px solid #b4b4b4;
    border-radius: 0 0 0.3rem 0.3rem;
    padding: 0 1.5rem;
}

.gallery .bor-rad-tab {
    border-radius: 0.3rem 0.3rem 0 0;
}

.gallery .bg-custom-6 {
    background-color: #aaaabb;
    color: #000 !important;
    border-left: 1px solid #313135;
    border-right: 1px solid #313135;
    border-top: 1px solid #313135;
}

.bg-custom-7 {
    background-color: #aaaabb;
    color: #000 !important;
    border-left: 1px solid #313135;
    border-right: 1px solid #313135;
    border-top: 1px solid #313135;
}

.gallery .nav-tabs,
.gallery .nav-item.show,
.gallery .nav-link.active {
    background-color: black;
    color: white;
    margin: 0;
    border: 1px solid black;
}

/* --- CARD WIDTH IN PORTFOLIO --- */

.portfolio .card {
    width: 24rem !important;
}

/* --- MODAL COLOURS --- */

.modal-backdrop {
    background-color: #af0103;
}

.form-control {
    background-color: #e7e7e7 !important;
}

.modal-title {
    color: #af0103;
}

/* --- EDUCATION ---- */

.font-education {
    font-size: 1.15rem;
}

/* --- UTILITY CLASSES --- */

.bg-custom-1 {
    background-color: #000 !important; /* nave */
}

.bg-custom-2 {
    background-color: #fefeff;
}

.bg-custom-3 {
    background-color: #000;
    color: #fff !important;
}

.bg-custom-4 {
    background-color: white;
}

.bg-custom-5 {
    background-color: rgba(17, 17, 17, 0.99);
}

.bg-custom-6 {
    background-color: #111;
}

.bg-custom-7 {
    background-color: #1d1d1d;
}

.bg-custom-8 {
    background-color: #080d10;
}

.bg-custom-9 {
    background-color: #313135;
    color: #fff !important;
}

.bg-custom-10 {
    background-color: #ececf5;
}

.bg-custom-11 {
    background-color: #313135 !important;
}

.bg-custom-12 {
    background-color: #000;
}

.bg-custom-13 {
    background-color: #fbdada;
}

.bor-rad-1 {
    border-radius: 0.3rem !important;
}

.bor-rad-2 {
    border-radius: 0.5rem;
}

.bor-rad-3 {
    border-radius: 1rem;
}

.bor-rad-top-missing {
    border-radius: 0 0 0.4rem 0.4rem;
}

.bor-rad-bot-missing {
    border-radius: 0.4rem 0.4rem 0 0 !important;
}

.bor-1 {
    border: 0;
}

.bor-custom-1 {
    border: 10px solid #000;
}

.bor-custom-2 {
    border-right: 4px groove #969595 !important;
    border-left: 4px groove #969595 !important;
    border-bottom: 4px groove #969595 !important;
}

.bor-custom-3 {
    border: 4px groove #969595 !important;
}

.bor-custom-4 {
    border-right: 4px groove #969595 !important;
    border-left: 4px groove #969595 !important;
}

.custom-hr {
    border-top: 1px groove rgba(150, 149, 149, 0.7) !important;
    display: block;
    width: 100%;
}

.font-col-custom-1 {
    color: #af0103;
}

.font-col-custom-2 {
    color: #000;
}

.font-col-custom-3 {
    color: pink;
}

.font-size-1 {
    font-size: 1.1rem;
}

.font-size-2 {
    font-size: 1.2rem;
}

.font-size-quotes {
    font-size: 1.08rem;
    font-style: italic;
}

.ltr-spacing {
    letter-spacing: 0.4px;
}

.ltr-spacing-2 {
    letter-spacing: 0.6px;
    display: inline-block;
}

.ltr-spacing-3{
    letter-spacing: 0.1rem;
}

.mar-0 {
    margin: 0;
}

.mar-auto {
    margin: 0 auto;
}

.mar-top-1 {
    margin-top: 1rem;
}

.mar-bot-1 {
    margin-bottom: 1rem;
}

.mar-top-spacing {
    margin-top: 1.1rem;
}

.mar-bot-spacing {
    margin-bottom: 1rem;
}

.mar-right-spacing {
    margin-right: .2rem;
}

.mar-x {
    margin: 0 0.001rem;
}

/* .mar-under-nav {
    margin-top: 3.5rem;
} */

.mar-custom-1 {
    margin-left: 0.01rem;
    margin-right: 0.01rem;
}

.pad-0 {
    padding: 0;
}

.pad-bot-1 {
    padding-bottom: 1rem;
}

.pad-top-1 {
    padding-top: 1rem;
}

.pad-top-nav {
    padding-top: 0.1rem;
}

.pad-x-spacing {
    padding-left: 0.92rem;
    padding-right: 0.92rem;
}

.btn-primary {
    background-color: #af0103 !important;
    border: 2px groove #969595 !important;
    font-weight: 500;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary.active {
    background-color: #520808 !important;
    border: 2px groove #969595 !important;
    color: white !important;
    font-weight: 500;
}

.btn-primary-2 {
    background-color: #af0103 !important;
    color: white;
    font-weight: 500 !important;
}

.btn-primary-2:hover,
.btn-primary-2:focus,
.btn-primary-2.focus,
.btn-primary-2.active {
    background-color: #7e0002 !important;
    color: white !important;
    font-weight: 700;
}

.button-custom {
    border-radius: 5px 5px 0 0 !important;
    background-color: #fff !important;
    color: black !important;
    font-weight: 500;
    border-top: 4px groove #969595 !important;
    border-left: 4px groove #969595 !important;
    border-right: 4px groove #969595 !important;
}

.button-custom:hover {
    border-radius: 5px 5px 0 0 !important;
    background-color: #b4b3b3 !important;
    color: black !important;
    font-weight: 500;
    border-top: 4px groove #969595 !important;
    border-left: 4px groove #969595 !important;
    border-right: 4px groove #969595 !important;
}

.button-custom.active,
.button-custom:focus,
.button-custom.focus {
    border-radius: 5px 5px 0 0 !important;
    border: 1px solid black !important;
    background-color: #af0103 !important;
    color: white !important;
    font-weight: 500;
    border-top: 4px groove #969595 !important;
    border-left: 4px groove #969595 !important;
    border-right: 4px groove #969595 !important;
}

/* --- PADDING FOR ADMIN AREA --- */

.contact-pad {
    padding: 0.4rem 2rem 0.4rem 2rem !important;
}

.resume-pad {
    padding: 2.2rem 2.4rem 0.5rem 2.4rem !important;
}

.portfolio-pad {
    padding: 1.9rem 1.7rem 1rem 1.7rem !important;
}

/* --- RESPONSIVE IMAGES --- */

.fill {
    object-fit: fill;
}
  
.contain {
    object-fit: contain;
}
  
.cover {
    object-fit: cover;
}
  
.scale-down {
    object-fit: scale-down;
}

.img-responsive {
    object-fit: cover;
    width: 100%;
    height: 250px;
}

.image_full {
    display: block !important;
}
 
.image_mobile {
   display: none !important;
}

.line_full {
    display: inline-block !important;
}
 
.line_mobile {
   display: none !important;
}

/* --- CAROUSEL --- */

.carousel-indicators {
    bottom: -15px;
}

/* --- VIDEO CONTACT/RESUME PAGE --- */

video {
    pointer-events: none;
    width: auto !important;
    height: auto !important;
    background-color: black;
    border-radius: 0.3rem;
    display: block;
}

video::-webkit-media-controls {
    display: none;
}

.video_full {
    display: block;
}

.video_mobile {
    display: none;
}

/* --- IMAGE --- */

.divider-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-height: 120px;
}

/* --- RESPONSIVE VIDEO --- */

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    -moz-border-radius: 0.3rem;
    border-radius: 0.3rem;
    overflow: hidden;
    box-shadow: 0 3px 10px rgba(255, 254, 254, 0.001);
}
  
.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

/* --- CURSOR --- */

a:hover,
button:hover, .video-responsive:hover, a.carousel-control-next:hover, .carousel-indicators li:hover {    
    /* Image by Jinsue Kim from Pixabay, https://pixabay.com/illustrations/finger-indication-cursor-hand-787548/, modified */
    cursor: url("img/my-cursor.png"), auto !important;
}

.ytp-button:hover, .ytp-cued-thumbnail-overlay:hover, .ytp-cued-thumbnail-overlay-image:hover {
    cursor: url("img/my-cursor.png") !important;
}

/* --- FOOTER REACT --- */

footer {
    color: #fff;
    background-color: #000;
}

/* --- Logo --- */

.custom-size {
    display: inline-block;
    width: 42px;
    margin: 0;
    vertical-align: middle;
    padding: 1px 12px 3px;
    border: 2px solid #af0103;
    border-radius: 50%;
    margin: 6px 14px 5px 0px;
}

/* --- Portfolio --- */

.btn-custom-black {
    background-color:#222;
    color: #ffffff;
    cursor: pointer !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.card-footer {
    padding: 0 !important;
    background-color: rgba(255, 255, 255, 0.0) !important; 
    border-top: 0 !important;
}


/* --- MEDIA QUERIES --- */

@media screen and (max-width: 281px) {
    .name {
        font-size: 0.75rem !important;
    }
    h1,
    .h1 {
        font-size: 1.8rem !important;
    }
    h2,
    .h2 {
        font-size: 1.5rem;
    }
}

@media (min-width: 282px) and (max-width: 300px) {
    .name {
        font-size: 0.9rem !important;
    }
    h1,
    .h1 {
        font-size: 1.8rem !important;
    }
    h2,
    .h2 {
        font-size: 1.5rem;
    }
}

@media (min-width: 301px) and (max-width: 325px) {
    .name {
        font-size: 1.05rem !important;
    }
    h1,
    .h1 {
        font-size: 1.8rem !important;
    }
    h2,
    .h2 {
        font-size: 1.5rem;
    }
}

@media (min-width: 325px) and (max-width: 350px) {
    .name {
        font-size: 1.2rem !important;
    }
    h1,
    .h1 {
        font-size: 1.85rem !important;
    }
    h2,
    .h2 {
        font-size: 1.6rem;
    }
}

@media (min-width: 351px) and (max-width: 400px) {
    .name {
        font-size: 1.43rem !important;
    }
    h1,
    .h1 {
        font-size: 1.95rem !important;
    }

}

@media screen and (max-width: 400px) {
    h2,
    .h2 {
        line-height: 0.2 !important;
        margin-top: 1.2rem;
    }
    .mar-under-nav {
        margin-top: 3.36rem;
    }
}

@media (min-width: 401px) and (max-width: 550px) {
    .name {
        font-size: 1.65rem !important;
    }
    h1,
    .h1 {
        font-size: 2.05rem !important;
        padding-bottom: .3rem;
    }
    h2,
    .h2 {
        font-size: 1.8rem !important;
    }
    .mar-under-nav {
        margin-top: 3.75rem;
    }
    .logo {
        padding: .07rem;
    }
}

@media screen and (max-width: 500px) {
    main {
        padding-left: .2rem !important;
        padding-right: .2rem !important;
    }
    .mar-bot-1 {
        margin-bottom: 0.8rem;
    }
    .mar-bot-spacing {
        margin-bottom: 0.8rem;
    }
    .mar-top-1 {
        margin-top: 0.8rem;
    }
    h2 {
        font-size: 1.7rem !important;
    }
    .custom-size {
        display: inline-block;
        width: 40px;
        margin: 0;
        vertical-align: middle;
        padding: 0px 12px 3px;
        border: 2px solid #af0103;
        border-radius: 50%;
        margin: 4px 10px 3px 0px;
    }
}

@media (min-width: 551px) and (max-width: 575px) {
    .mar-under-nav {
        margin-top: 3.6rem;
    }
}

@media (min-width: 576px) and (max-width: 769px) {
    .mar-under-nav {
        margin-top: 3.7rem;
    }
}

@media (min-width: 551px) and (max-width: 769px) {
    h1,
    .h1 {
        font-size: 2.15rem !important;
        padding-bottom: .3rem;
    }
    h2,
    .h2 {
        font-size: 1.9rem !important;
    }
}

@media screen and (max-width: 576px) {
    h2,
    .h2 {
        font-size: 1.75rem !important;
    }
    h3,
    .h3 {
        font-size: 1.55rem !important;
    }
    h4,
    .h4 {
        font-size: 1.4rem !important;
    }
    h5,
    .h5 {
        font-size: 1.15rem !important;
    }
    body {
        background-color: #000;
    }
    .hide-show {
        display: none;
    }
    .bg-mobile {
        background-color: #111 !important;
    }
}

@media screen and (min-width: 576px) {
    .card-group>.card {
        flex: none;
    }
    .show-hide {
        display: none;
    }
    h1 {
        padding-bottom: .5rem;
    }
    h2 {
        margin-bottom: .15rem;
    }
}

@media screen and (max-width: 769px) {
    .image_full {
        display: none !important;
    }
    .image_mobile {
        display: block !important;
    }
    .iframe-container {
        position: relative;
        width: 100%;
        height: 80%;
        padding-bottom: 74%; /* Ratio 16:9 ( 100%/16*9 = 56.25% ) */
    }
    .hide {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .pad-right-spacing {
        padding-right: 9px !important;
    }
    .mar-right-space {
        margin-right: .2rem;
    }
    .pad-left-spacing {
        padding-left: 7px !important;
    }   
    .mar-under-nav {
        margin-top: 2.6rem;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .pad-left {
        padding-left: 15px !important;
    }
    .mar-right-space {
        margin-right: .4rem;
    }
}

@media screen and (max-width: 992px) {
    .margin-bottom-custom {
        margin-bottom: 0 !important;
    }
    .pad-bottom-spacing {
        padding-bottom: 15px !important;
    } 
    #a { 
        order: 1; 
    }
    #b { 
        order: 3; 
    }
    #c{ 
        order: 2; 
    }
    #d { 
        order: 4; 
    }
}

/* --- HEADER LINE AND RESPONSIVE VIDEO--- */

@media screen and (max-width: 400px) {
    h1, .h1 {
        font-size: 1.7rem !important;
        margin-top: .305rem !important;
    }
    h2, .h2 {
        font-size: 1.5rem !important;
    } 
    h3, .h3 {
        font-size: 1.35rem !important;
    }
    .header-line {
        border-radius: 2px;
        width: 194px;
        margin-bottom: 0 !important;
    }
    .header-spacing {
        padding-bottom: 1.4rem !important;
    }
    .line_full {
        display: none !important;
    }
    .line_mobile {
       display: inline-block !important;
    }
    .video_full {
        display: none;
    }
    .video_mobile {
        display: block !important;
        background-color: black;
        overflow: hidden;
    }
    .home-footer {
        padding-top: 0.6rem;
        border-bottom: 2px solid #111 !important;
    }
    .bg-custom-1 {
        background-color: #000 !important; 
    }
}

@media (min-width: 401px) and (max-width: 575px) {
    h1, .h1 {
        font-size: 1.8rem !important;
    }
    h2, .h2 {
        font-size: 1.6rem !important;
    }
    h3, .h3 {
        font-size: 1.35rem !important;
        padding-bottom: 0 !important;
    }
    .header-line {
        border-radius: 2px;
        width: 208px;
    }
    .line_full {
        display: none !important;
    } 
    .line_mobile {
       display: inline-block !important;
    }
    .video_full {
        display: none;
    }
    .video_mobile {
        display: block !important;
        background-color: black;
        overflow: hidden;
    }
    .home-footer {
        padding-top: 0.6rem;
        border-bottom: 2px solid #111 !important;
    }
    .bg-custom-1 {
        background-color: #000 !important; /* footer */
    }
}

@media (min-width: 576px) and (max-width: 769px) {
    .header-line {
        border-radius: 2px;
        width: 412px;
    }
    .video_full {
        display: none;
    }
    .video_mobile {
        display: block !important;
        background-color: black;
        overflow: hidden;
    }
}